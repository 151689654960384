.results-tables__container {
  position: relative;
}

.project-results-panel {
  width: 926px;
  height: calc(100vh - 56px);
  padding: 24px;
  overflow-y: auto;
  border-right: 1px solid #ededed;
}

.project-results-panel__pipelines-gradient {
  position: absolute;
  bottom: 20px;
  left: 950px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  justify-content: space-between;
  // min-width: 500px;
  width: 100%;
  max-width: 500px;
  height: 100px;
  padding: 12px 16px;
  background-color: #fff;
  border: 1px solid #ededed;
  border-radius: 3px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.04);
}

.project-results-panel__pipelines-gradient-scale-values {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;

  .project-results-panel__pipelines-gradient-scale {
    display: flex;
    align-self: stretch;
    width: 100%;
    height: 10px;
    border-radius: 2px;
    // background-color: #000;
  }
}

.project-results-panel__pipelines-gradient-values {
  display: flex;
  flex: 1;
  align-items: center;
  align-self: stretch;
  justify-content: center;
}

.charts__segments-scale_values {
  display: flex;
  flex: 1;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  margin-top: 5px;

  & p {
    color: #aaa;
    font-size: 10px;
  }
}

.project-results-panel-header {
  display: flex;
  align-items: center;
}

.project-results-panel__buttons {
  position: absolute;
  right: 0;
  display: flex;
}

.project-results-panel__title-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  &.initial {
    margin-bottom: 24px;
  }
}

.project-results-panel__title {
  color: #333;
  font-weight: 600;
  font-size: 14px;
  font-style: normal;
  line-height: 20px;
}

.project-results-panel__export-btn {
  display: flex;
  align-items: center;
  width: 105px;
  color: #333;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;

  &:disabled {
    cursor: default;

    &:not(.exporting) {
      color: #aaa;
    }

    & .project-results-panel__export-icon path {
      fill: #aaa;
    }
  }

  &:not(:disabled):hover {
    color: #29af62;
  }

  &:not(:disabled):active {
    color: #249d58;

    & .project-results-panel__export-icon path {
      fill: #249d58;
    }
  }
}

.project-results-panel__export-icon,
.project-results-panel__aspo-icon,
.project-results-panel__applied-icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.project-results-panel__progress-icon {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  animation: spin 4s linear infinite;

  &.export-progress-icon path {
    fill: #29af62;
  }
}

.project-results-panel__aspo-btn {
  display: flex;
  align-items: center;
  width: 118px;
  margin-right: 32px;
  color: #333;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;

  &:disabled {
    cursor: default;

    &:not(.applyingAspo) {
      color: #aaa;
    }

    & .project-results-panel__aspo-icon path {
      fill: #aaa;
    }
  }

  &:not(:disabled):hover {
    color: #00aeef;
  }

  &:not(:disabled):active {
    color: #009cd6;

    & .project-results-panel__aspo-icon path {
      fill: #009cd6;
    }
  }
}

.project-results-panel__message {
  margin-top: 24px;
  font-weight: normal;
  font-size: 11px;
  font-style: normal;
  line-height: 14px;

  &.error {
    margin-top: 0;
  }
}

.project-results-panel__message-details {
  position: relative;
  width: 532px;
  height: 36px;
  margin-top: 20px;
  padding: 8px 40px 8px 12px;
  color: #333;
  font-weight: 600;
  font-size: 10px;
  line-height: 20px;
  text-align: left;
  background-color: #f5f5f5;
  border-radius: 4px 4px 4px 4px;

  &:hover {
    background-color: #f7f7f7;
  }

  &.extended {
    border-radius: 4px 4px 0 0;

    & .project-results-panel__arrow-icon {
      transform: rotate(180deg);
    }
  }
}

.project-results-panel__arrow-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 16px;
  height: 16px;
}

.project-results-panel__message-details-text {
  width: 532px;
  padding: 8px 12px;
  color: #333;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  border: 1px solid #ededed;
  border-radius: 0 0 4px 4px;
}

.dashboard {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 16px 16px 0;
  background: #f7f7f7;
  border-radius: 2px;
}

.dashboard__item {
  position: relative;
  width: 207px;
  margin-bottom: 16px;
  padding: 20px;
  background: linear-gradient(0deg, #fff, #fff), #f3f4f8;
  border-radius: 2px;

  &.short {
    width: 80px;
  }

  &.af_item {
    width: 157px;
  }
}

.dashboard__item-dem {
  margin-bottom: 8px;
  color: #aaa;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.dashboard__item-value {
  color: #333;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.dashboard__bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.min-max-values {
  position: absolute;
  top: 32px;
  right: 20px;
  color: #999;
  font-weight: normal;
  font-size: 10px;
  font-style: normal;
  line-height: 16px;
  text-align: right;
}

.results__tab-list {
  display: -webkit-flex;
  display: flex;
  margin-top: 20px;

  &.underline {
    border-bottom: 1px solid #ededed;

    & .results__tab {
      padding-bottom: 12px;
    }

    &.none {
      border-bottom: 1px solid white;
    }
  }

  &.tables-tab-list {
    justify-content: space-between;
    margin: 12px 0;
  }
}

.results__tab {
  position: relative;
  margin-right: 16px;
  color: #aaa;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  cursor: pointer;
  transition: all 0.25s ease;

  &:hover {
    color: #666;
  }

  &.react-tabs__tab--selected,
  &.active {
    color: #333;
  }
}

.results__icon {
  position: relative;
  margin-right: 16px;
  cursor: pointer;
  transition: all 0.25s ease;

  & path {
    fill: #aaa;
  }

  &:not(.react-tabs__tab--disabled):hover path {
    fill: #666;
  }

  &.react-tabs__tab--selected path {
    fill: #333;
  }

  &.react-tabs__tab--disabled {
    cursor: default;
  }
}

.results__table-tabs {
  display: flex;
  align-items: center;
}

.results_tabs-step-row {
  position: relative;
  width: 128px;
  visibility: hidden;

  &.visible {
    visibility: visible;
  }
}

.input.results_tabs-step-input {
  padding-right: 72px;
}

.results_tabs-step-input-unit {
  position: absolute;
  top: 6px;
  right: 8px;
  color: #aaa;
  font-weight: 500;
  font-size: 10px;
  font-style: normal;
  line-height: 20px;
}

.table__nodes-table,
.table__sources-table,
.table__segments-table {
  min-width: 100%;
}

.table-head__row {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #f7f7f7;
  -webkit-transform: translate3d(0, 0, 0);

  &::after {
    position: absolute;
    bottom: -1px;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 1px;
    background: #ededed;
    content: '';
  }
}

.table-row {
  &.looping {
    background: rgba(243, 189, 65, 0.08);
  }
}

.table-head__cell {
  padding: 10px;
  color: #aaa;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  white-space: nowrap;
  text-align: left;
  background: #f7f7f7;
  border-right: 1px solid #ededed;
  border-left: 1px solid #ededed;

  &:first-child {
    border-left: none;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }

  &:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  &.right {
    text-align: right;
  }
}

.table-data {
  position: relative;
  padding: 10px;
  color: #333;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  text-align: right;
  border: 1px solid #e9eaee;

  &.left {
    text-align: left;
  }

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }

  &.table-status-img {
    text-indent: 14px;
  }

  &.table-status-img::before {
    position: absolute;
    top: 14px;
    left: 10px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    content: "";
  }

  &.table-status-img.working::before {
    background-color: #2da45e;
  }

  &.table-status-img.stopped::before {
    background-color: #aaa;
  }
}

.results-tables__container .scrollarea {
  width: 100%;
  max-height: calc(100vh - 416px);
  overflow: hidden;
  border-top: 1px solid #ededed;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  border-left: 1px solid #ededed;

  &.vertical-scroll {
    width: calc(100% - 13px);
  }

  &.scrolled {
    & .table-head__row {
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12);
    }
  }
}

.results-tables__container .scrollarea-content {
  display: inline-block;
  min-width: calc(100% + 2px);

  &:focus {
    outline: none;
  }
}

.results-tables__container .scrollbar-container.horizontal {
  position: absolute;
  bottom: -13px;
  left: 0;
  width: calc(100% - 13px);
  padding: 4px 0;
  border-right: solid 1px #ededed;
  border-bottom: solid 1px #ededed;
  border-left: solid 1px #ededed;
}

.results-tables__container .scrollbar-container.vertical {
  position: absolute;
  right: 0;
  bottom: 0;
  height: calc(100vh - 416px);
  padding: 0 4px;
  border-top: solid 1px #ededed;
  border-right: solid 1px #ededed;
  border-bottom: solid 1px #ededed;
}

.scrollbar-container.horizontal .scrollbar {
  position: relative;
  height: 4px;

  &::after {
    position: absolute;
    top: 0;
    right: 4px;
    left: 3px;
    height: 4px;
    background-color: #ddd;
    border-radius: 8px;
    content: "";
  }
}

.scrollbar-container.vertical .scrollbar {
  position: relative;
  width: 4px;

  &::after {
    position: absolute;
    top: 3px;
    bottom: 4px;
    left: 0;
    width: 4px;
    background-color: #ddd;
    border-radius: 8px;
    content: "";
  }
}

.subscript {
  font-size: 8px;
  vertical-align: sub;
}
