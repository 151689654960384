.new-project-container {
  display: flex;
}

.map-container {
  flex: 1;
}

.left-col {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 312px;
  height: 100vh;
  padding: 20px;
  background-color: #fff;

  &__row {
    margin-bottom: 12px;
  }
}

.left-col__title {
  color: #333;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.create-project-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
}

.create-project-buttons__button {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 128px;
  height: 60px;
  padding: 12px;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  outline: none;

  &.create-project-buttons__button-new-project {
    path {
      fill: #00aeef;
    }

    &.active {
      color: #fff;
      background: #00aeef;

      path {
        fill: #fff;
      }
    }
  }

  &.create-project-buttons__button-file {
    path {
      fill: #815df6;
    }

    &.active {
      color: #fff;
      background: #815df6;

      path {
        fill: #fff;
      }
    }
  }

  &-icon {
    margin-bottom: 4px;
  }

  &-file {
    color: #333;
    background-color: #fff;
  }
}

.left-col__sub-title {
  margin-bottom: 2px;
  color: #aaa;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
}

.left-col__buttons {
  display: flex;
  align-items: center;
}

.left-col__create-button {
  margin-right: 16px;
}

.left-col__file-name {
  position: relative;
  padding: 4px 28px 4px 0;
  color: #333;
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  text-indent: 28px;
  border: 2px solid #f5f5f5;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.08);
}

.left-col__file-icon {
  position: absolute;
  top: 8px;
  left: 8px;
}

.left-col__delete-icon {
  position: absolute;
  top: 8px;
  right: 8px;
}

.left-col__row-label {
  display: block;
  margin-bottom: 2px;
  color: #aaa;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  text-align: left;
}

.left-col__with-prefix-row {
  display: flex;
  align-items: center;
  width: 100%;
}

.left-col__link-icon {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 32px;
  height: 32px;
  margin-right: 2px;
  border: 1px solid #ededed;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.left-col__with-prefix-input {
  width: 100%;
}
