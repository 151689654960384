.results__single-chart {
  display: flex;
  flex-flow: column;
  padding-top: 12px;
}

.results__single-chart-dropdown {
  align-self: flex-end;
  width: 200px;
  margin-bottom: 30px;
}
