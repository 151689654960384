.charts__gant {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  min-height: 438px;
}

.charts__gant-chart__labels-lines-gradient {
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 5% 90% 50px;
  width: 100%;

  .charts__gant-chart__labels {
    display: grid;
    grid-auto-rows: 40px;
    grid-template-columns: 1fr;
    height: 400px;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;

    & .charts__gant-chart__labels-text {
      color: #999;
      font-size: 10px;
      white-space: nowrap;
    }
  }

  .charts__gant-chart__lines {
    // flex: 10;
    display: grid;
    grid-auto-rows: 40px;
    grid-template-columns: 1fr;
    width: 100%;
    // row-gap: 10px;
    height: 16px;
    height: 400px;
    margin: 0 10px;
    overflow: auto;

    .charts__gant-chart__lines-line {
      display: flex;
      flex-direction: row;
      align-self: stretch;
      justify-content: space-between;
      width: 1500px;
      margin-right: 5px;
      // border-right: 0.5px solid #000;

      .charts__gant-chart__lines-line__block {
        display: grid;
        grid-auto-columns: 1fr;
        width: 100%;
        // column-gap: 0.5px;
        // row-gap: 0.5px;
        // background:linear-gradient(#ccc,#ccc) right/0.5px 100% no-repeat;
        // grid-auto-rows: 40px;
        height: 16px;
        // margin-right: 5px;
      }
    }
  }

  .charts__gant-chart__gradient {
    display: flex;
    margin-left: 10px;

    .charts__gant-chart__gradient-scale {
      display: flex;
      align-self: stretch;
      width: 8px;
      margin-left: 10px;
      border-radius: 2px;
    }
  }
}

.charts__gant-chart__scale-scroll {
  margin: 0 50px;
  overflow: auto;

  .charts__gant-chart__scale {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    // padding-bottom: 10px;
    // margin: 10px 50px;
    width: 1500px;

    & p {
      margin-right: 5px;
      color: #999;
      font-size: 10px;
    }
  }
}

//SCROLLBARS

.charts__gant-chart__lines::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  border-left: 0.5px solid #f2f2f2;
}

.charts__gant-chart__lines::-webkit-scrollbar:horizontal {
  display: none;
  width: 12px;
  height: 12px;
  border-left: 0.5px solid #f2f2f2;
}

.charts__gant-chart__lines::-webkit-scrollbar-track {
  width: 16px;
}

.charts__gant-chart__lines::-webkit-scrollbar-track:horizontal {
  width: 16px;
  height: 16px;
}

.charts__gant-chart__lines::-webkit-scrollbar-thumb {
  background-color: #ddd;
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 14px;
}

.charts__gant-chart__lines::-webkit-scrollbar-thumb:horizontal {
  background-color: #ddd;
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 14px;
}

.charts__gant-chart__scale-scroll::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  border-left: 0.5px solid #f2f2f2;
}

.charts__gant-chart__scale-scroll::-webkit-scrollbar-track:horizontal {
  width: 16px;
  height: 16px;
}

.charts__gant-chart__scale-scroll::-webkit-scrollbar-thumb:horizontal {
  background-color: #ddd;
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 14px;
}

.charts__gant-chart__labels::-webkit-scrollbar {
  display: none;
  width: 12px;
  border-left: 0.5px solid #f2f2f2;
}

.charts__gant-chart__labels::-webkit-scrollbar-track {
  width: 16px;
}

.charts__gant-chart__labels::-webkit-scrollbar-thumb {
  background-color: #ddd;
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 14px;
}

.charts__gant_scale {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  margin-bottom: 22px;
  margin-left: 30px;
  padding-bottom: 10px;
}

.charts__gant-scale_values {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: space-between;
  margin-left: 10px;

  & p {
    color: #aaa;
    font-size: 10px;
  }
}

.charts__gant-chart__legend {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-self: stretch;
  justify-content: flex-start;
  padding-top: 20px;

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px 12px 0 0;
    // background-color: #f5f5f5;
    padding: 4px 8px;

    & p {
      color: #333;
      font-size: 10px;
    }
  }
}
