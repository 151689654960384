@import './InterFonts.css';
@import '~reset-css-complete/reset.css';

* {
  box-sizing: border-box;
}

input {
  border: none;
  outline: none;
}

button {
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
}

div[role='button']:focus-visible {
  outline: none;
}

div:focus-visible,
canvas:focus-visible {
  outline: none;
}

html,
body {
  font-weight: normal;
  font-family: Inter, sans-serif;
}

.relative {
  position: relative;
}

.d-flex {
  display: flex;
}

.align-center {
  align-items: center;
}
