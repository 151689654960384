.details-panel__container {
  position: relative;
  width: 596px;
  height: calc(100vh - 56px);
  padding: 16px;
  background: white;
  border-left: 1px solid #ededed;
}

.details-panel__close-button {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 16px;
  height: 16px;

  &:not(:hover) svg path {
    fill: #aaa;
  }
}

.details-panel__header {
  margin-bottom: 16px;
  border-bottom: solid 1px #ededed;
}

.pipes-catalog__table {
  min-width: 100%;
}

.details-panel__container .scrollarea {
  width: 100%;
  max-height: calc(100vh - 136px);
  overflow: hidden;
  border: 1px solid #ededed;

  &.vertical-scroll {
    width: calc(100% - 13px);
  }
}

.details-panel__container .scrollarea-content {
  display: inline-block;
  width: calc(100% + 2px);

  &:focus {
    outline: none;
  }
}

.details-panel__container .scrollbar-container.vertical {
  position: absolute;
  right: 15px;
  bottom: 16px;
  height: calc(100vh - 136px);
  padding: 0 4px;
  border-top: solid 1px #ededed;
  border-right: solid 1px #ededed;
  border-bottom: solid 1px #ededed;
}

.details-panel__no-model-message {
  color: #333;
  font-weight: normal;
  font-size: 11px;
  font-style: normal;
  line-height: 14px;
}

.well-model-panel__chart-container {
  height: 424px;
}

.well-model-panel__tab-list-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.well-model-panel__table-caption {
  color: #aaa;
  font-weight: 500;
  font-size: 10px;
  font-style: normal;
  line-height: 16px;
}

.details-panel__updated-indicator {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-left: 3px;
  background: #333;
  border-radius: 50%;
}

.height-profile-panel__points-container {
  margin: 28px 0 12px 0;
}

.height-profile-panel__add-btn {
  &.active-object__add-point-btn {
    margin: 0;
  }
}

.height-profile-panel__point {
  position: relative;
  width: 276px;

  &:not(:first-child) {
    margin-top: 8px;
  }
}
